import GhostContentAPI from '@tryghost/content-api'

const api = new GhostContentAPI({
  url: "https://api.traversingtradition.com",
  key: "c7ccef1a814ec50457f34866db",
  version: "v3",
});

export async function getFilteredPosts(limit, filter, page) {
  if (typeof (page) === 'undefined') page = 1;
  return api.posts
    .browse({
      limit: limit,
      include: 'tags,authors',
      filter: filter,
      page: page
    })
    .catch(err => {
      console.error(err);
    });
}

export async function getCarouselPosts() {
  return getFilteredPosts(3, "featured: false", )
}

export async function getFeaturedPosts(page) {
  return getFilteredPosts(3, "featured: true", page);
}

export async function getRelatedPosts(post) {
  if(post==="loading")
    return

  const obj_post = post 
  console.log(post)
  const { id, primary_tag } = obj_post ;
  console.log(id,primary_tag);
  const { slug } = primary_tag;
  // return posts that match same primary tag
  // Could change this in the future to return posts that most
  // match the original tags, via a sorting algorithm
  return getFilteredPosts(4, "tags:" + slug + "+id:-" + id);
}

export async function getVersePosts(page) {

  return getFilteredPosts(10, "tag:verse-prose", page);
}


export async function getShortPosts(page) {
  return getFilteredPosts(10, "tag:shorts", page);
}


export async function getDialoguePosts(page) {
  return getFilteredPosts(10, "tag:dialogues", page);
}


export async function getArticlePosts(page) {
  return getFilteredPosts(10, "tag:-shorts+tag:-verse-prose+tag:-dialogues", page);
}


export async function getAuthorPosts(author, page) {
  return getFilteredPosts(10, "primary_author:" + author, page)
}


export async function getPost(slug) {
  return api.posts
    .read(
      { slug: slug }, { include: "authors,tags" }
    )
    .catch(err => {
      console.error(err);
    });
}

export async function addMember(email) {
  const nodeUrl = "https://api.traversingtradition.com/node"
  return await fetch(nodeUrl + "/members", {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify({email: email}) // body data type must match "Content-Type" header
  });
}