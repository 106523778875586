import React from "react";
import "../Styles/footer.scss";
import { Facebook, Twitter, Instagram } from "react-bootstrap-icons";
import Nav from "react-bootstrap/Nav";
import { addMember } from "../Functions/api";

export default function Footer (){
  const [email, setEmail] = React.useState('');
  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await addMember(email);
    console.log(response);
  }
  return (
    <div className='footer'>
      <div className='row'>
        <div className='column'>
          <h3>Read More</h3>
          <Nav.Link href='/shorts/1'>
            <h5>Shorts</h5>
          </Nav.Link>
          <Nav.Link href='/articles/1'>
            <h5>Articles</h5>
          </Nav.Link>
          <Nav.Link href='/verseprose/1'>
            <h5>Verse & Prose</h5>
          </Nav.Link>
        </div>
        <div className='column'>
          <h3>Get in Touch</h3>
          <Nav.Link href='/aboutUs'>
            <h5>About Us</h5>
          </Nav.Link>
          <Nav.Link href='mailto:admin@traversingtradition.com?subject=Feedback'>
            <h5>contact us</h5>
          </Nav.Link>
          <Nav.Link href='mailto:admin@traversingtradition.com?subject=JoiningTT'>
            <h5>Write for us</h5>
          </Nav.Link>
        </div>
        <div className='column'>
          <form className='news-letter' onSubmit={handleSubmit}>
            <h5>Sign-Up For Our NewsLetter</h5>
            <input onChange={(e) => setEmail(e.target.value)} type='email' value={email} placeholder='Email Address'/>
            <button className='btn-action' type='submit'>
              Subscribe
            </button>
          </form>
          <div id='social-icons'>
            <Nav.Link
              href='https://www.facebook.com/traversingtrad'
              target='_blank'>
              <Facebook />
            </Nav.Link>
            <Nav.Link href='https://twitter.com/TraversingTrad' target='_blank'>
              <Twitter />
            </Nav.Link>
            <Nav.Link
              href='https://www.instagram.com/traversingtrad/'
              target='_blank'>
              <Instagram />
            </Nav.Link>
          </div>
        </div>
      </div>
    </div>
  );
};

