import React, { Component } from "react";
import "../Styles/articleView.scss";
import { Facebook, Twitter, Instagram } from "react-bootstrap-icons";
import { Nav, Spinner } from "react-bootstrap/";
import ArticleLink from "./ArticleLink";
import { getPost, getRelatedPosts } from "../Functions/api";
import Placeholder from "../assets/Traversing Tradition.png";
export default class articleView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: "loading",
      relatedPosts: [],
    };
  }
  async componentWillMount() {
    const slug = this.props.history.location.pathname.split("/")[2];
    const post = await getPost(slug);
    const relatedPosts = await getRelatedPosts(post);
    this.setState({
      post: post,
      relatedPosts: relatedPosts,
    });
    console.log(post);
  }

  render() {
    return this.state.post === "loading" ? (
      <div className="loading-container">
        <Spinner animation="border" className="spinner" variant="danger" />
      </div>
    ) : (
      <>
        <div
          className="article-header"
          style={
            this.state.post.feature_image
              ? { background: `url(${this.state.post.feature_image})` }
              : { backgroundImage: `url(${Placeholder})` }
          }>
          <div className="cover">
            <h1>{this.state.post.title || "The Epic Temporary Title"}</h1>
            <h4 id="genre">
              {this.state.post.primary_tag.name || "Philosophy"}
            </h4>
            <div id="overview">
              {this.state.post.excerpt ||
                " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley oftype and scrambled it to make a type specimen book."}
            </div>
            <div id="time">
              {this.state.post.reading_time + " Minutes" || "32 Minutes read"}
            </div>
            <section id="section03">
              <a href="#section04">
                <span></span>
              </a>
            </section>
          </div>
        </div>
        <div
          className="article-body"
          dangerouslySetInnerHTML={{
            __html: this.state.post.html || "error",
          }}></div>

        <div className="article-info row">
          <div className="article-author">
            <img
              src={
                this.state.post.primary_author.profile_image ||
                "https://secure.gravatar.com/avatar/3e390cab2160e256ae9447d5f5ce51cd?s=100&amp;r=pg&amp;d=mm"
              }
              alt="author"
              width="100"
              height="100"
              originals="100"
              scale="2"></img>
            <div className="author-name">
              {this.state.post.primary_author.name || "Sabrina Amrane"}
            </div>
            <div className="article-date">
              {this.state.post.created_at
                .substring(0, 10)
                .split("-")
                .reverse()
                .join("-") || "MARCH 29, 2021"}
            </div>
          </div>
          <ol className="tags">
            {this.state.post.tags.map((tag) => {
              return <p>{tag.name}</p>;
            }) ||
              "AUTHOR EGYPT FEMINISM ISLAM ISLAMOPHOBIA MUSLIM WOMEN  NAWAL EL-SAADAWI ORIENTALISM"}
          </ol>
          <div className="share">
            <Nav.Link
              href="https://www.facebook.com/traversingtrad"
              target="_blank">
              <Facebook />
            </Nav.Link>
            <Nav.Link href="https://twitter.com/TraversingTrad" target="_blank">
              <Twitter />
            </Nav.Link>
            <Nav.Link
              href="https://www.instagram.com/traversingtrad/"
              target="_blank">
              <Instagram />
            </Nav.Link>
          </div>
        </div>

        <div className="related">
          <div className="related-title">Related:</div>
          <div className="row">
            <ArticleLink post={this.state.relatedPosts[0] || "Loading"} />
            <ArticleLink post={this.state.relatedPosts[1] || "Loading"} />
            <ArticleLink post={this.state.relatedPosts[2] || "Loading"} />
            {this.state.relatedPosts[3] ? (
              <ArticleLink post={this.state.relatedPosts[3] || "Loading"} />
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </>
    );
  }
}
