import React from "react";
import "../Styles/articleLink.scss";
import Placeholder from "../assets/Traversing Tradition.png";

const ArticleLink = ({ post }) => {
  let {
    title,
    primary_author,
    excerpt,
    feature_image,
    slug,
    custom_excerpt,
    created_at,
  } = post;
  let author = undefined;
  if (typeof primary_author !== "undefined") {
    author = primary_author.name;
  }
  return (
    <div className="article-container">
      <div className="col">
        <div className="card-holder">
          <div className="hidden-container">
            <div className="link-author">{author || "temp author"}</div>
            <div className="article-date">
              {created_at.substring(0, 10).split("-").reverse().join("-") ||
                "MARCH 29, 2021"}
            </div>
          </div>
          <a href={`/post/${slug}`}>
            <img
              src={feature_image || Placeholder}
              alt={title || "loading"}
              className="link-img"
            />
          </a>
        </div>
        <a href={`/post/${slug}`}>
          <div className="link-title">{title || "temp Title"}</div>
        </a>
        <div className="link-description">
          {custom_excerpt || excerpt.split(".")[0] + "." || "simple Summary"}
        </div>
      </div>
    </div>
  );
};

export default ArticleLink;
