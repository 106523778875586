import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Spinner from "react-bootstrap/Spinner";
import "../Styles/carouselCard.scss";

const CarouselCard = ({ post }) => {
  if (post === undefined) {
    return (
      <div className="bg-image">
        <div className="carouCard" style={{ textAlign: "-webkit-center" }}>
          <Spinner
            animation="border"
            className="spinner"
            variant="danger"
            style={{ marginTop: "4em" }}
          />
        </div>
      </div>
    );
  }
  let { title, primary_author, created_at, slug, feature_image } = post;
  return (
    <div
      className="bg-image"
      style={feature_image ? { background: `url(${feature_image})` } : {}}>
      <a href={`/post/${slug}`}>
        <div className="carouCard">
          <h3>{title || "Title"}</h3>
          <h4>
            {created_at.substring(0, 10).split("-").reverse().join("-") +
              "|" +
              primary_author.name || "Date | Author"}
          </h4>
        </div>
      </a>
    </div>
  );
};

export default function carousel({ posts }) {
  return (
    <Carousel fade>
      <Carousel.Item>
        <CarouselCard post={posts[0]} />
      </Carousel.Item>
      <Carousel.Item>
        <CarouselCard post={posts[1]} />
      </Carousel.Item>
      <Carousel.Item>
        <CarouselCard post={posts[2]} />
      </Carousel.Item>
    </Carousel>
  );
}
