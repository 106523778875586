import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./Components/navbar";
import Home from "./Components/homeView";
import Article from "./Components/articleView";
import About from "./Components/aboutUs";
import Footer from "./Components/footer";
import Articles from "./Components/Articles";
import Shorts from "./Components/shorts";
import Dialogues from "./Components/Dialogues";
import VerseProse from "./Components/VerseProse";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles/index.scss";

ReactDOM.render(
  <React.StrictMode>
    <>
      <Navbar />
      <Router>
        <Switch>
          <Route path="/" exact component={Home}></Route>
          <Route path="/aboutUs" exact component={About}></Route>
          <Route path="/articles/:id" exact component={Articles}></Route>
          <Route path="/shorts/:id" exact component={Shorts}></Route>
          <Route path="/Dialogues/:id" exact component={Dialogues}></Route>
          <Route path="/VerseProse/:id" exact component={VerseProse}></Route>
          <Route path="/post/:id" exact component={Article}></Route>
        </Switch>
      </Router>
      <Footer />
    </>
  </React.StrictMode>,
  document.getElementById("root")
);
