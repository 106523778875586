import React from "react";
import "../Styles/verse.scss";
import { Col, Row, Image } from "react-bootstrap";
import img from "../assets/Traversing Tradition.png";

const Verse = ({ post }) => {
  let { title, html, feature_image, slug } = post;
  return (
    <Col className="verse-content-container">
      <Row>
        <Col className="img-container">
          <Image src={feature_image ? feature_image : img} fluid />
        </Col>
        <Col>
          <a href={`/post/${slug}`} style={{ color: "black" }}>
            <h4>{title}</h4>
          </a>
          <span
            dangerouslySetInnerHTML={{
              __html: html || "error",
            }}></span>
        </Col>
      </Row>
    </Col>
  );
};

export default Verse;
