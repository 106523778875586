import React from "react";
import "../Styles/aboutUs.scss";

const aboutUs = () => {
  return (
    <div className="aboutUs">
      <div className="about">
        <h1>About Us</h1>
        <p>
          Traversing Tradition is a registered non-profit, online publication
          founded in February 2018 with the goal of addressing the most pressing
          issues of our time. We strive to provide varied perspectives on modern
          society through the lenses of Islamic theology, Eastern and Western
          philosophy, and historical analysis.
        </p>
      </div>
      <div className="about">
        <h1>Our Mission</h1>
        <p>
          Modern philosophies and ideologies shape our assumptions about the
          world, the nature of truth, and morality. Our mission is to critically
          examine these premises through a Muslim worldview.
        </p>
      </div>
      <div className="about">
        <h1>Editorial Disclaimer</h1>
        <p>
          Material published by Traversing Tradition is meant to foster
          scholarly inquiry and rich discussion. The views, opinions, beliefs,
          or strategies represented in published articles and subsequent
          comments do not necessarily represent the views of Traversing
          Tradition or any employee thereof. Articles on this site may be
          redistributed in other non-commercial publications after reading our
          terms and contacting the editorial team.
        </p>
      </div>
    </div>
  );
};

export default aboutUs;
