import React, { Component } from "react";
import "../Styles/articleView.scss";
import Carousel from "./carousel";
import HoverCard from "./hoverCards";
import Verse from "./verse";
import "../Styles/home.scss";
import { getCarouselPosts, getVersePosts, getFeaturedPosts } from "../Functions/api";

const SecTitle = ({ title }) => {
  return <h1 className='text-center pubs'>{title}</h1>;
};

const HoverCards = ({ posts }) => {
  let [derp] = posts;

  if (typeof derp === "undefined") {
    return (
      <div className='container-fluid hover-container'>
        <div className='row'>
          <div className='col'>
            <HoverCard post={"lol"} />
          </div>
          <div className='col'>
            <HoverCard post={"lol"} />
          </div>
          <div className='col'>
            <HoverCard post={"lol"} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className='hover-container'>
        <div className='row'>
          <div className='col'>
            <HoverCard post={posts[0]} />
          </div>
          <div className='col'>
            <HoverCard post={posts[1]} />
          </div>
          <div className='col'>
            <HoverCard post={posts[2]} />
          </div>
        </div>
      </div>
    );
  }
};

export default class homeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      verses: [],
      featured: [],
      videos: [],
    };
  }

  async componentWillMount() {
    const posts = await getCarouselPosts();
    const verseProse = await getVersePosts();
    const featured = await getFeaturedPosts();
    fetch(
      "https://youtube.googleapis.com/youtube/v3/search?channelId=UCpal_xfkDZjpD4b5_1Ys0HQ&key=AIzaSyBWrB4foeHNZCNbB5e_BtX6xpuGAKxSkhs&maxResults=2&type=video&order=date"
    )
      .then((response) => response.json())
      .then((videos) => {
        this.setState({ videos: videos });
        console.log(this.state.videos.items[0]);
      });

    this.setState({ posts: posts, verses: verseProse, featured: featured });
  }

  render() {
    return (
      <>
        <Carousel posts={this.state.featured} />
        <SecTitle title='Recent Publications' />
        <HoverCards posts={this.state.posts} />
        <SecTitle title='Video' />
        <div className='home-container'>
          <div className='row'>
            <div className='col'>
              <div className='video-container'>
                <iframe
                  width='650'
                  height='500'
                  src={`https://www.youtube.com/embed/${
                    this.state.videos.length !== 0
                      ? this.state.videos.items[0].id.videoId
                      : "CemVs"
                  }`}
                  title='YouTube'
                />
              </div>
            </div>
            <div className='col'>
              <div className='video-container'>
                <iframe
                  width='650'
                  height='500'
                  src={`https://www.youtube.com/embed/${
                    this.state.videos.length !== 0
                      ? this.state.videos.items[1].id.videoId
                      : "CemVs"
                  }`}
                  title='YouTube'
                />
              </div>
            </div>
          </div>
        </div>
        <a href='/VerseProse/1'>
          <SecTitle title='Verse & Prose' />
        </a>
        <div className='verse-prose-container'>
          <div
            className='col'
            style={{
              gap: "5em",
              flexDirection: "column",
              display: "flex",
              marginTop: "2em",
              marginBottom: "2em",
            }}>
            <Verse post={this.state.verses[0] || "loading"} />
            <Verse post={this.state.verses[1] || "loading"} />
          </div>
        </div>
      </>
    );
  }
}
