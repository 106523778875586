import React from "react";
import "../Styles/hoverCards.scss";
import Nav from "react-bootstrap/Nav";
import Placeholder from "../assets/Traversing Tradition.png";

const hoverCards = ({ post }) => {
  let {
    title,
    primary_tag,
    published_at,
    excerpt,
    custom_excerpt,
    feature_image,
    slug,
    primary_author,
  } = post;
  let tag,
    pub_date = undefined;
  let author = undefined;
  if (typeof primary_author !== "undefined") {
    author = primary_author.name;
  }
  if (typeof primary_tag !== "undefined" && primary_tag !== null) {
    tag = primary_tag.name;
    pub_date = published_at.substring(0, 10);
  }
  return (
    <div id="cardContainer">
      <div className="card">
        <img src={feature_image || Placeholder} alt="Bae" />
        <div className="hidden-text">
          <h3>{tag || "category"}</h3>
          <p>
            {custom_excerpt ||
              excerpt ||
              "The concept of continuous moral progress over time, born out of the Enlightenment era and continuing into postmodernity, has become a paradigm by which we critique the past, gauge our ethical compass, and form policy."}
          </p>
        </div>
      </div>
      <Nav.Link href={`/post/${slug}`}>
        <h4 id="title">{title || "title"}</h4>
      </Nav.Link>
      <h5 id="date">
        {pub_date || "date"} | {author || "author"}
      </h5>
    </div>
  );
};

export default hoverCards;
