import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "../Styles/navbar.scss";
import { Facebook, Twitter, Instagram, Search } from "react-bootstrap-icons";
import React, { useState } from "react";

const Mynavbar = () => {
  const page = window.location.href.split("/")[3];
  const [language, setLanguage] = useState("EN");
  const [showSearch, setShowSearch] = useState(false);
  const [style, setStyle] = useState({
    EN: true,
    AR: false,
    FR: false,
    SP: false,
  });

  const handleChange = (lang) => {
    let temp = style;
    for (let key in temp) {
      if (key === lang) {
        temp[key] = true;
      } else temp[key] = false;
    }
    setStyle(temp);
    setLanguage(lang);
  };

  return (
    <div className="nav-parent">
      <Navbar bg="light" variant="light" expand="lg">
        <Navbar.Brand href="/">
          <h1 id="traversing">Traversing</h1>
          <br />
          <h1 id="tradition">Tradition</h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            className="mr-auto justify-content-center"
            id="nav-links"
            style={{ flex: 1 }}>
            <Nav.Link
              href="/articles/1"
              style={
                page === "articles" ? { color: "#d22929" } : { color: "black" }
              }>
              Articles
            </Nav.Link>
            <Nav.Link
              href="/Shorts/1"
              style={
                page === "Shorts" ? { color: "#d22929" } : { color: "black" }
              }>
              Shorts
            </Nav.Link>
            <Nav.Link
              href="/Dialogues/1"
              style={
                page === "Dialogues" ? { color: "#d22929" } : { color: "black" }
              }>
              Dialogues
            </Nav.Link>
            <Nav.Link
              href="/VerseProse/1"
              style={
                page === "VerseProse"
                  ? { color: "#d22929" }
                  : { color: "black" }
              }>
              Verse & Prose
            </Nav.Link>
            <Nav.Link
              href="/aboutUs"
              style={
                page === "aboutUs" ? { color: "#d22929" } : { color: "black" }
              }>
              About Us
            </Nav.Link>
          </Nav>
          {/* <div id="language">
            <Nav.Link
              href="#defaultLang"
              onClick={() => handleChange("EN")}
              style={style.EN ? { color: "#D22929" } : {}}>
              EN
            </Nav.Link>
            <p>/</p>
            <Nav.Link
              href="#AR"
              onClick={() => handleChange("AR")}
              style={style.AR ? { color: "#D22929" } : {}}>
              AR
            </Nav.Link>
            <p>/</p>
            <Nav.Link
              href="#SP"
              onClick={() => handleChange("SP")}
              style={style.SP ? { color: "#D22929" } : {}}>
              SP
            </Nav.Link>
            <p>/</p>
            <Nav.Link
              href="#FR"
              onClick={() => handleChange("FR")}
              style={style.FR ? { color: "#D22929" } : {}}>
              FR
            </Nav.Link>
          </div> */}
          <div id="social-icons">
            <Nav.Link
              href="https://www.facebook.com/traversingtrad"
              target="_blank">
              <Facebook />
            </Nav.Link>
            <Nav.Link href="https://twitter.com/TraversingTrad" target="_blank">
              <Twitter />
            </Nav.Link>
            <Nav.Link
              href="https://www.instagram.com/traversingtrad/"
              target="_blank">
              <Instagram />
            </Nav.Link>
            <Nav.Link onClick={() => setShowSearch(!showSearch)}>
              <Search />
            </Nav.Link>
          </div>
        </Navbar.Collapse>
      </Navbar>
      <div
        className="search-bar"
        style={
          showSearch
            ? { width: "100%", opacity: "100%" }
            : { width: "0", opacity: "0", height: "0" }
        }>
        <div className="gcse-search"></div>
      </div>
    </div>
  );
};

export default Mynavbar;
