import React, { Component } from "react";
import { getDialoguePosts } from "../Functions/api";
import ArticleLink from "./ArticleLink";
import { Spinner, Row } from "react-bootstrap/";
import Pagination from "react-bootstrap/Pagination";
import "../Styles/pagination.scss";

class Dialogues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: ["loading"],
      page: 1,
      pages: ["loading"],
    };
  }

  async componentWillMount() {
    const page = parseInt(this.props.history.location.pathname.split("/")[2]);
    this.setState({ page: page });
    const posts = await getDialoguePosts(page);
    let pages = [];
    for (let number = page; number <= parseInt(page) + 3; number++) {
      console.log("entering");
      if (number == posts.meta.pagination.pages + 1) break;
      if (parseInt(page) + 3 !== 4 && number === page && parseInt(page) !== 2)
        pages.push(
          <Pagination.Item key={number - 2} href={`/Dialogues/${number - 2}`}>
            {number - 2}
          </Pagination.Item>
        );
      if (parseInt(page) + 3 !== 4 && number === page)
        pages.push(
          <Pagination.Item key={number - 1} href={`/Dialogues/${number - 1}`}>
            {number - 1}
          </Pagination.Item>
        );
      pages.push(
        <Pagination.Item
          key={number}
          active={number == page}
          href={`/Dialogues/${number}`}>
          {number}
        </Pagination.Item>
      );
    }
    this.setState({
      posts: posts,
      page: page,
      pages: pages,
    });
  }

  render() {
    return this.state.posts[0] === "loading" ? (
      <div className="loading-container">
        <Spinner animation="border" className="spinner" variant="danger" />
      </div>
    ) : (
      <div className="pagination-container">
        <Row>
          {this.state.posts.map((post) => (
            <ArticleLink key={post.id} post={post} />
          ))}
        </Row>
        <div className="pagination">
          <Pagination.Prev
            disabled={this.state.page == 1}
            href={`/Dialogues/${this.state.page - 1}`}
          />
          {this.state.page != 1 &&
          this.state.page != 2 &&
          this.state.page != 3 ? (
            <Pagination.Ellipsis disabled={true} />
          ) : (
            <div></div>
          )}
          <Pagination>{this.state.pages}</Pagination>
          {this.state.page + 3 < this.state.posts.meta.pagination.pages ? (
            <Pagination.Ellipsis disabled={true} />
          ) : (
            <div></div>
          )}
          <Pagination.Next
            disabled={
              this.state.page === this.state.posts.meta.pagination.pages
            }
            href={`/Dialogues/${this.state.page + 1}`}
          />
        </div>
      </div>
    );
  }
}

export default Dialogues;
